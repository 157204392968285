
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
  },
  setup() {
    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
    });
    const tableHeader = ref([
      {
        name: "Equipement",
        key: "loc_reference",
        sortable: true,
      },
      {
        name: "N° PO",
        key: "toc_code",
        sortable: true,
      },
      {
        name: "Quantité Disponible",
        key: "qte",
        sortable: true,
      },
      {
        name: "Réappro.",
        key: "reappro",
        sortable: true,
      },
      {
        name: "Echéance de stock",
        key: "date_liv",
        sortable: true,
      },
      {
        name: "Site",
        key: "site",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Sites",
        key: "site",
        label: "site",
      },
      {
        name: "Réapprovisionnement",
        key: "status_reappro",
        label: "status_reappro",
      },
      {
        name: "Disponibilité",
        key: "status_qte",
        label: "status_qte",
      },
    ]);

    const ExcelFormat = ref({
      "Référence": "loc_reference",
      "Quantité": "qte",  
      "N° PO": "toc_code",
      "Disponible le": "date_liv",
      Site: "site",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const formatMoney = (n) => {
      if (!n) n = 0;
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const returnUrlOffre = (data) => {
      return `/stillboard/offres/${data}`;
    };

    const returnUrlCommande = (data) => {
      return `/commande/${data}/livraisons`;
    };

    const formatHoverDateD = (data) => {
      if (!data.date_old_ldc) {
        return `La date de livraison n'a connue aucune variation depuis le ${formatDate(
          data.toc_date_creation
        )} `;
      }
      return `${formatDateAgo(
        data.fraicheur
      )}, la date de livraison est passée du ${formatDate(
        data.date_old_ldc
      )} au ${formatDate(data.date_ldc)}`;
    };

    const affDetail = async () => {
      state.drawer = true;
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Suivi des commandes fournisseur", []);

      let myList = await getAxios("/getstock");
      state.initialMyList = myList.records;
      state.myList = myList.records;
      //console.log(myList.records);
      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      formatMoney,
      affDetail,
      formatHoverDateD,
      returnUrlOffre,
      returnUrlCommande,
      tableFilters,
      ExcelFormat,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
