<template>
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <transition name="fade">
    <div class="card" v-if="!state.loaderEnabled">
      <div class="card-header border-0 pt-6">
        <SearchAndFilter
          :listInitial="state.initialMyList"
          :filters="tableFilters"
          @returnSearch="state.myList = $event"
          @returnLoadingDatatable="state.loadingDatatable = $event"
          ExcelFilename="Stillnetwork_Stock"
          :ExcelFormat="ExcelFormat"
        ></SearchAndFilter>
      </div>
      <div class="card-body pt-0">
        <div
          v-if="state.myList.length == 0"
          class="
            alert
            bg-light-primary
            alert-primary
            d-flex
            align-items-center
            p-5
            mb-10
          "
        >
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">Aucun équipement de stock</h4>
            <span
              >Il n'existe aucun équipement de stock à afficher correspondant à vos
              critères de recherche.</span
            >
          </div>
        </div>
        <Datatable
          v-if="state.myList && state.myList.length > 0"
          :table-data="state.myList"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          empty-table-text="Aucune commande à afficher."
          :key="state.loadingDatatable"
        >
          <template v-slot:cell-loc_reference="{ row: data }">
            <div class="text-gray-800"  style="margin-left: 5px; min-width: 500px;">
              <img :src="`data:image/jpg;base64,${data.soc_logo}`"  v-if="data.soc_logo" alt="image" style="width: auto; max-height: 40px; max-width:75px; background-color: #ececec; float: left;margin-right:10px;" />
              <div v-else class="badge badge-white" style="max-height: 40px; max-width:75px; background-color: #ececec; float: left;margin-right:10px;">{{ data.soc_nom_societe }}</div>
              <div class="text-gray-400">
                <span class="w-150px fs-8">{{ data.loc_reference }}</span>
              </div>
              {{ data.loc_designation }}
            </div>
          </template>
          <template v-slot:cell-qte="{ row: data }">
            <span class="badge badge-white w-175px fs-6 text-gray-900">
            <span v-if="data.qte>0" class="text-dark">{{ data.qte }}</span>
            <span v-else class="text-danger">Indisponible</span>
            </span>
          </template>
          <template v-slot:cell-reappro="{ row: data }">
            <span v-if="data.reappro>0" class="badge badge-white w-200px fs-6 text-gray-900">
              + {{ data.reappro }} 
              <span v-if="data.date_reappro" class="text-gray-400">dés le {{ formatDate(data.date_reappro) }}</span>
              <span v-else class="text-gray-400">(date en attente)</span>
            </span>
          </template>
          <template v-slot:cell-date_liv="{ row: data }">
            <span v-if="data.date_liv" class="text-dark">{{ formatDate(data.date_liv) }}</span>
          </template>
          <template v-slot:cell-toc_code="{ row: data }">
            <div class="text-muted ms-3" v-html="data.toc_code" style="min-width: 100px;"></div>
          </template>
          <template v-slot:cell-site="{ row: data }">
            <span v-html="data.site" class="badge badge-secondary fs-7 text-gray-700"></span>
          </template>
        </Datatable>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
  },
  setup() {
    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
    });
    const tableHeader = ref([
      {
        name: "Equipement",
        key: "loc_reference",
        sortable: true,
      },
      {
        name: "N° PO",
        key: "toc_code",
        sortable: true,
      },
      {
        name: "Quantité Disponible",
        key: "qte",
        sortable: true,
      },
      {
        name: "Réappro.",
        key: "reappro",
        sortable: true,
      },
      {
        name: "Echéance de stock",
        key: "date_liv",
        sortable: true,
      },
      {
        name: "Site",
        key: "site",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Sites",
        key: "site",
        label: "site",
      },
      {
        name: "Réapprovisionnement",
        key: "status_reappro",
        label: "status_reappro",
      },
      {
        name: "Disponibilité",
        key: "status_qte",
        label: "status_qte",
      },
    ]);

    const ExcelFormat = ref({
      "Référence": "loc_reference",
      "Quantité": "qte",  
      "N° PO": "toc_code",
      "Disponible le": "date_liv",
      Site: "site",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const formatMoney = (n) => {
      if (!n) n = 0;
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const returnUrlOffre = (data) => {
      return `/stillboard/offres/${data}`;
    };

    const returnUrlCommande = (data) => {
      return `/commande/${data}/livraisons`;
    };

    const formatHoverDateD = (data) => {
      if (!data.date_old_ldc) {
        return `La date de livraison n'a connue aucune variation depuis le ${formatDate(
          data.toc_date_creation
        )} `;
      }
      return `${formatDateAgo(
        data.fraicheur
      )}, la date de livraison est passée du ${formatDate(
        data.date_old_ldc
      )} au ${formatDate(data.date_ldc)}`;
    };

    const affDetail = async () => {
      state.drawer = true;
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Suivi des commandes fournisseur", []);

      let myList = await getAxios("/getstock");
      state.initialMyList = myList.records;
      state.myList = myList.records;
      //console.log(myList.records);
      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      formatMoney,
      affDetail,
      formatHoverDateD,
      returnUrlOffre,
      returnUrlCommande,
      tableFilters,
      ExcelFormat,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
</script>
